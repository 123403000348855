import { useState, useEffect } from 'react';

const getOrientation = () => window.screen?.orientation?.type;

let previousType = null;

const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(getOrientation());

    const handleChangeOrientation = (event) => {
        const type = event.target.type;
        if (previousType !== type) {
            previousType = type;
            setOrientation(type);
        }
    };

    useEffect(() => {
        screen.orientation.addEventListener("change", handleChangeOrientation);
        return () => {
            screen.orientation.removeEventListener("change", handleChangeOrientation)
        }
    }, []);

    return orientation;
}

export default useScreenOrientation;